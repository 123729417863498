import { ref } from "vue";
import { defineStore } from "pinia";
import type { DocumentTemplate } from "@/models/documentTemplates";
import documentTemplateService from "@/services/modules/documentTemplates";
import { DOCUMENT_TEMPLATES_SCHEMA_VERSION } from "@/helpers/constants/documentTemplates";
import { usePromiseWrapper } from "@/hooks/common";
import type { IResponseMeta } from "@/models/common";

export const useDocumentTemplatesStore = defineStore(
  "documentTemplates",
  () => {
    const documentTemplates = ref<DocumentTemplate[]>([]);
    const searchMeta = ref<IResponseMeta>();

    const { fetchWrapper: create } = usePromiseWrapper(
      async (template: DocumentTemplate) => {
        template.content.version = DOCUMENT_TEMPLATES_SCHEMA_VERSION;

        const newTemplate = await documentTemplateService.create(template);

        documentTemplates.value.unshift(newTemplate);
        return newTemplate;
      },
      {
        onError: (error) => {
          throw error; // Rethrow for validation
        }
      }
    );

    const { fetchWrapper: getAll, loading: getAllIsLoading } =
      usePromiseWrapper(async (params?: Record<string, unknown>) => {
        const { templates, meta } =
          await documentTemplateService.getAll(params);

        searchMeta.value = meta;
        documentTemplates.value = templates;
        return templates;
      });

    const { fetchWrapper: getById } = usePromiseWrapper(
      async (templateId: string) => {
        const template = await documentTemplateService.getById(templateId);

        const currentIndex = documentTemplates.value.findIndex(
          (existingTemplate) => existingTemplate?.id === template.id
        );

        currentIndex === -1
          ? documentTemplates.value.unshift(template)
          : (documentTemplates.value[currentIndex] = template);

        return template;
      }
    );

    const { fetchWrapper: update } = usePromiseWrapper(
      async (template: DocumentTemplate & { id: string }) => {
        template.content.version = DOCUMENT_TEMPLATES_SCHEMA_VERSION;

        const updatedTemplate = await documentTemplateService.update(template);

        const currentIndex = documentTemplates.value.findIndex(
          (existingTemplate) => existingTemplate?.id === template.id
        );

        if (currentIndex !== -1 && !!updatedTemplate) {
          documentTemplates.value[currentIndex] = updatedTemplate;
          return updatedTemplate;
        }
      },
      {
        onError: (error) => {
          throw error; // Rethrow for validation
        }
      }
    );

    const { fetchWrapper: deleteById } = usePromiseWrapper(
      async (templateId: string) => {
        await documentTemplateService.deleteById(templateId);

        documentTemplates.value = documentTemplates.value.filter(
          (template) => template.id !== templateId
        );
      }
    );

    getAll();

    return {
      create,
      deleteById,
      getAll,
      getAllIsLoading,
      getById,
      update,
      documentTemplates,
      searchMeta
    };
  }
);
