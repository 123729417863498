<template>
  <lf-modal
    :title="$t('DOCUMENT_TEMPLATES.DELETE_MODAL.TITLE')"
    :close="() => emit('close')"
    :small="true"
  >
    <template v-slot:content>
      <p class="text-gray-700 px-6 pb-4 break-words">
        {{
          $t("DOCUMENT_TEMPLATES.DELETE_MODAL.BODY", {
            name: template?.name ?? ""
          })
        }}
      </p>
      <div class="border-t border-gray-200 p-6 flex justify-end">
        <modal-buttons :on-cancel="() => emit('close')" :on-delete="submit" />
      </div>
    </template>
  </lf-modal>
</template>

<script setup lang="ts">
import { useDocumentTemplatesStore } from "@/stores/documentTemplates";
import type { DocumentTemplate } from "@/models/documentTemplates";
import LfModal from "@/components/ui/Modal.vue";
import ModalButtons from "@/components/ui/ModalButtons.vue";

const { template } = defineProps<{
  template: DocumentTemplate;
}>();

const emit = defineEmits<{
  close: [];
}>();

const { deleteById } = useDocumentTemplatesStore();

const submit = async () => {
  if (!template?.id) {
    return;
  }

  await deleteById(template.id);

  emit("close");
};
</script>
