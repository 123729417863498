import { axiosClient } from "@/services/client";
import endpoints from "@/services/endpoints";
import { formatString } from "@/helpers/formatting";
import type { DocumentTemplate } from "@/models/documentTemplates";
import type { IPaginatedResponse } from "@/models/common";

class DocumentTemplateService {
  async create(template: DocumentTemplate) {
    const response = await axiosClient<{ data: DocumentTemplate }>({
      method: "POST",
      url: endpoints.DOCUMENT_TEMPLATES.CREATE,
      data: template
    });

    return response.data.data;
  }

  async getAll(params?: Record<string, unknown>) {
    const response = await axiosClient<IPaginatedResponse<DocumentTemplate>>({
      method: "GET",
      url: endpoints.DOCUMENT_TEMPLATES.ALL,
      params
    });

    return { templates: response.data.data, meta: response.data.meta };
  }

  async getById(id: string) {
    const response = await axiosClient<{ data: DocumentTemplate }>({
      method: "GET",
      url: formatString(endpoints.DOCUMENT_TEMPLATES.SINGLE, id),
      data: { id }
    });

    return response.data.data;
  }

  async update(template: DocumentTemplate & { id: string }) {
    const response = await axiosClient<{ data: DocumentTemplate }>({
      method: "PUT",
      url: formatString(endpoints.DOCUMENT_TEMPLATES.SINGLE, template.id),
      data: template
    });

    return response.data.data;
  }

  async deleteById(templateId: string) {
    await axiosClient<{ data: DocumentTemplate[] }>({
      method: "DELETE",
      url: formatString(endpoints.DOCUMENT_TEMPLATES.SINGLE, templateId)
    });
  }
}

const documentTemplateService = new DocumentTemplateService();
export default documentTemplateService;
